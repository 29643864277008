import React from "react"
import '@formatjs/intl-locale/polyfill';
import '@formatjs/intl-getcanonicallocales/polyfill'
import Layout from "../components/layout"
import Seo from "../components/seo"
import { FormattedMessage, injectIntl } from "gatsby-plugin-react-intl"
import {Container} from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

const NotFoundPage = ({ intl }) => (
    <Layout>
            <Seo lang={intl.locale}
                 title={intl.formatMessage({ id: "title" })}
                 description={intl.formatMessage({ id: "description" })}
            />
            <Container>
                    <div className={`pass`}>
                            <div className={`pass-area`}>
                                    <div className={`not-found`}>
                                            <div className={`warning`}>
                                                    <div className={`icon`}>
                                                            <FontAwesomeIcon icon="exclamation-triangle" size="4x" />
                                                    </div>
                                                    <div className={`text`}>
                                                            <FormattedMessage id="shared.notFound" />
                                                    </div>
                                            </div>
                                    </div>
                            </div>
                    </div>
            </Container>
    </Layout>
)

export default injectIntl(NotFoundPage)
